<template>
  <div class="elite-tabs-wrapper-content">
    <h3 class="mb-4">{{ label.name_translate[language] }}</h3>
    <div class="row">
      <div class="col-9">
        <dl class="row">
          <dt class="col-sm-4">{{ $t("LABELS.CATEGORY") }}</dt>
          <dd class="col-sm-8">
            {{$t(`LABELS.${label.category}`)}}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.EXCERPT") }}</dt>
          <dd class="col-sm-8">
            <div v-html="label.excerpt_translate[language]"></div>
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd class="col-sm-8" v-if="label.created_at">
            {{ $formatDate(label.created_at) }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd class="col-sm-8" v-if="label.updated_at">
            {{ $formatDate(label.updated_at) }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>

import { loadLanguageAsync } from "@/plugins/i18n";
export default {
  name: "label-view-global",

  components: {},

  props: ["label"],

  data() {
    return {};
  },

  computed: {},

  created(){
    this.language = this.$i18n.locale;
  },

  methods: {},

  mounted() {},

  watch: {
    label(label) {},
  },
};
</script>
