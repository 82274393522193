<template>
  <div class="container-fluid">
    <label-form
      :loading="loading"
      :contact="contact"
      :property="property"
      :labelData="label"
      :formErrors="formErrors"
      @labelSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import defaultTaxes from "@/constants/defaultTaxes";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultLabel from "../defaultLabel";
import LabelForm from "../partials/LabelForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    LabelForm,
  },

  mixins: [alertLeave],
  props: ["property", "contact"],

  data() {
    const label = cloneDeep(defaultLabel);
    const me = this.$store.getters["profile/me"];
    return {
      label: label,
      formErrors: null,
      loading: false,
    };
  },

  created() {},

  methods: {
    async handleSubmit(label) {
      this.loading = true;

      const labelData = cloneDeep(label);
      delete labelData.id;

      try {
        await this.$store.dispatch("labels/add", labelData);
        this.$notify({
          type: "success",
          message: this.$t("LABELS.LABEL_ADDED"),
        });
        const label = this.$store.getters["labels/label"];
        this.$emit("onViewLabel", label, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
