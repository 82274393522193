<template>
  <form
    class="add-form"
    ref="profile_form"
    @submit.prevent="handleSubmit"
    @keydown.enter.prevent="() => {}"
  >
    <div class="form-wrapper">
      <base-input
        :label="`${$t('LABELS.CATEGORY')} (*)`"
        :placeholder="$t('LABELS.CATEGORY')"
      >
        <el-select
          :clearable="true"
          class="select-primary pagination-select"
          :placeholder="$t('LABELS.CATEGORY')"
          v-model="label.category"
          @change="onFormChanged"
        >
          <el-option
            class="select-primary"
            v-for="(item, key) in CATEGORIES"
            :key="key"
            :label="$t(`LABELS.${item}`)"
            :value="item"
          >
          </el-option>
        </el-select>
      </base-input>
      <validation-error :errors="apiValidationErrors.category" />
    </div>
    <div class="form-wrapper">
      <base-input
        :label="`${$t('LABELS.SLUG')}`"
        :placeholder="$t('LABELS.SLUG')"
        :disabled="label.id"
        v-model="label.slug"
        @change="onFormChanged()"
      />
      <validation-error :errors="apiValidationErrors.slug" />
    </div>

    
    
    <div class="form-wrapper full">
      <ul class="tabs-lang">
        <li :class="{ active: activeLangTab === 'fr' }" @click="activeLangTab = 'fr'">Fr</li>
        <li :class="{ active: activeLangTab === 'en' }" @click="activeLangTab = 'en'">En</li>
      </ul>

      <div v-if="activeLangTab === 'fr'">
        <base-input
          :label="`${$t('LABELS.NAME')}`"
          :placeholder="$t('LABELS.NAME')"
          v-model="label.name_translate.fr"
          @change="onFormChanged()"
        />
        <base-input
        :label="$t('LABELS.EXCERPT')"
        :placeholder="$t('LABELS.EXCERPT')"
      >
        <textarea v-model="label.excerpt_translate.fr" class="form-control" :placeholder="$t('LABELS.EXCERPT')"></textarea>
        </base-input>
      </div>
      <div v-if="activeLangTab === 'en'">
        <base-input
          :label="`${$t('LABELS.NAME')}`"
          :placeholder="$t('LABELS.NAME')"
          v-model="label.name_translate.en"
          @change="onFormChanged()"
        />
      <base-input
        :label="$t('LABELS.EXCERPT')"
        :placeholder="$t('LABELS.EXCERPT')"
      >
        <textarea v-model="label.excerpt_translate.en" class="form-control" :placeholder="$t('LABELS.EXCERPT')"></textarea>
      </base-input>
      </div>
    </div>
    

    <div class="submit-wrapper">
      <base-button
        type="button"
        class="btn btn-sm elite-submit"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading"></i>
        {{
          label.id ? $t("LABELS.EDIT_LABEL") : $t("LABELS.ADD_LABEL")
        }}
      </base-button>
    </div>
  </form>
</template>
<script>
  import Vue from 'vue';
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import models from "@/constants/models";
import {
  CATEGORIES
} from "@/constants/labels";

export default {
  layout: "DashboardLayout",

  components: {
    HtmlEditor,
    BaseInput,
    ValidationError,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: ["labelData", "formErrors", "loading", "contact", "property"],

  data() {
    let labelData = cloneDeep(this.labelData);
    labelData = this.$fillUserOrganizationData(labelData);
    return {
      label: labelData,
      CATEGORIES,
      permissions: [],
      permissionsModel: {},
      models: models,
      activeLangTab: 'fr',
    };
  },

  created() {
    if(!this.label.name_translate || this.label.name_translate.length == 0){
      this.label.name_translate = {
          fr: '',
          en: '',
        }
    }
    if(!this.label.excerpt_translate || this.label.excerpt_translate.length == 0){
      this.label.excerpt_translate = {
          fr: '',
          en: '',
        }
    }
  },

  mounted() {},

  methods: {
    async handleSubmit() {
      let labelData = cloneDeep(this.label);
      labelData = this.$fillUserOrganizationData(labelData);
      this.$emit("labelSubmitted", labelData);
    },

    onFormChanged() {
      console.log(this.label);
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    labelData(labelData) {
      if (labelData) {
        this.label = {
          ...this.label,
          ...cloneDeep(labelData),
        };
      }
    },
  },
};
</script>
<style>
.tabs-lang {
  display: flex;
  list-style-type: none;
  padding: 0;
}
.tabs-lang li {
  padding: 10px;
  cursor: pointer;
}
.tabs-lang li.active {
  font-weight: bold;
}
</style>
